import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import {
    ContentTemplate,
    FilterBar,
    PageTemplateWrapper,
    RelatedContentStyled
} from "@resourcehub/resourcehub-components";
import configGenerator from "@resourcehub/resourcehub-configuration";

const ContentContainer = styled.main`
    overflow: hidden;
`;
const ResourcePreviewContainer = styled.div`
    background-color: #e5e5e5;
    padding-bottom: 4rem;
`;

const ContentPageTemplate = ({ data, location, pageContext }) => {
    const { item, items, site } = data;

    // Filter items with those in the selected stream
    const filteredItems = items.edges.filter((edge) => edge.node.data.streams.includes(pageContext.streamId));
    const config = configGenerator({
        market: site.siteMetadata.market,
        site: "consumer",
        locale: site.siteMetadata.lang,
        page: "dynamic-content"
    });
    config.page.helmet.meta_title = item.data.seo_title || "";
    config.page.helmet.meta_description = item.data.seo_description || "";
    config.page.helmet.canonical_url = item.data.canonical_url || "";

    const { copyData } = config.page.content;

    return (
        <PageTemplateWrapper config={config}>
            <ContentContainer>
                <ResourcePreviewContainer>
                    <FilterBar copyData={copyData} vertical={site.siteMetadata.legacy.vertical} />
                    <ContentTemplate
                        copyData={copyData}
                        domain={site.siteMetadata.legacy.domain}
                        item={item}
                        location={location}
                        vertical={site.siteMetadata.legacy.vertical}
                    />
                    <RelatedContentStyled
                        copyData={copyData}
                        items={filteredItems}
                        siteMetadata={site.siteMetadata}
                        title={copyData.similar_resources}
                    />
                </ResourcePreviewContainer>
            </ContentContainer>
        </PageTemplateWrapper>
    );
};

export default ContentPageTemplate;

export const pageQuery = graphql`
    query contentQuery($rawId: String!, $streamId: String!) {
        item: airtable(id: { eq: $rawId }) {
            ...ItemFragment
        }
        items: allAirtable(
            filter: {
                table: { eq: "Items" }
                id: { ne: $rawId }
                data: { streams: { eq: $streamId }, market: { eq: "CN" } }
            }
        ) {
            edges {
                node {
                    ...ItemsFragment
                }
            }
        }
        site {
            ...SiteFragment
        }
        stream: airtable(id: { eq: $streamId }) {
            ...StreamFragment
        }
    }
`;
